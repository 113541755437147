import * as dompack from "dompack";
import * as mso from "@mod-mso/webdesigns/mso/mso";
import * as whintegration from "@mod-system/js/wh/integration";

import "@mod-clubtorso/web/components/header/header";
import "@mod-mso_templates/webdesigns/installa/installa";
import "./widgets/booking/booking";
import "./widgets/twocols/twocols";

import "@mod-nubiumreviews/webcomponents";

import "./clubtorso.scss";
import "./clubtorso.lang.json";

dompack.onDomReady(() => {
  new mso.MSOSite();

  if (whintegration.config.site.reviewsfooter) {
    let footer = dompack.qS(".footer > .ww-grid");

    let element = document.createElement("div");
    element.classList.add("footerreviews");
    element.innerText = whintegration.config.site.reviewsfooter;
    footer.appendChild(element);
  }
});
